/* eslint-disable import/no-anonymous-default-export */
export default {
  // Anmelden
  login: "Anmelden",
  login_text: "Willkommen zurück! Melde dich an und lass uns loslegen!",
  email_user: "Gib deine E-Mail oder deinen Benutzernamen ein",

  // Startseite
  sup_admin: "Super Admin",
  admin: "Admin",
  mang: "Verwaltung",
  all_reg: "Alle Registrierungen",
  top_merch: "Top Händler",
  app_merch: "Händler anlegen",
  all_deal: "Alle Deals",
  all_coup: "Alle Coupons",
  all_mark: "Alle Händler",
  kyb: "KYB",
  all_sales: "Alle Verkäufe",
  reports: "Meldungen",
  blc: 'Blockiert',
  rls: " Freigegeben",
  security: 'Sicherheit',
  msg: "Nachricht",
  logout: "Abmelden",

  // Meldungen
  // reports: "Meldungen",
  new: "Neu",
  solved: "Gelöst",
  see_more: "Mehr anzeigen",
  deal: "Angebot",
  coup: "Gutschein",
  comment: "Kommentar",
  merch: "Händler",
  id: "ID",
  reporter: "Reporter",
  reDate: "Meldedatum",
  reason: "Grund",
  check: "Prüfen",
  coupons: "Gutscheine",
  info: "Information",
  no_coup: "Kein Gutschein verfügbar",
  ph_no: "Telefonnummer",
  address: "Adresse",
  open_h: "Öffnungszeiten",
  open_t: "Öffnungszeit",
  report: "Meldung",
  send_res: "Grund senden",
  del: "Löschen",

  // Angebot Meldung
  dDetails: "Angebotsdetails",
  desc: "Beschreibung",
  rec_deals: "Empfohlene Angebote",
  go_deal: "Zum Angebot",
  no_comment: "Keine Kommentare gefunden",

  // Gutschein Meldung
  condition: "Bedingungen",
  sun: "Sonntag",
  mon: "Montag",
  tue: "Dienstag",
  wed: "Mittwoch",
  thr: "Donnerstag",
  fri: "Freitag",
  sat: "Samstag",

  // Kommentar Meldungen
  ava_delivery: "Verfügbare Lieferung",
  comments: "Kommentare",

  // Verwaltungsbildschirm
  cr_reporter: "Mitarbeiter erstellen",
  country: "Land",

  // Mitarbeiter erstellen Bildschirm
  cr_a_repo: "Einen Mitarbeiter erstellen",
  pers_id: "Persönliche ID",
  name: "Name",
  name_holder: "Name eingeben",
  email: "E-Mail Adresse",
  email_holder: "E-Mail Adresse eingeben",
  pass: "Passwort",
  pass_holder: "Passwort eingeben",
  con_pass: "Passwort bestätigen",
  con_holder: "Passwort erneut eingeben",
  all_repo: "Alle Mitarbeiter",
  save: "Speichern",
  edit: "Bearbeiten",
  // del: "Löschen",

  // Alle Registrierungen Bildschirm
  user_cred: "Benutzer mit Credits",
  user_Nocred: "Benutzer ohne Credits",
  merchs: "Händler",
  search: "Suche",
  sort: "Sortieren nach",
  a_z: "A bis Z",
  z_a: "Z bis A",
  new_old: "Neueste zuerst",
  old_new: "Älteste zuerst",
  reg_date: "Registrierungsdatum",
  val_til: "Gültig bis",
  not_found: "Nicht gefunden",
  details: "Details",
  blc_user: "Benutzer blockieren",
  block_popup: "Benutzer erfolgreich blockiert",

  // Top Händler
  reg: "Registriert",
  other: "Andere",
  find_merch: "Händler finden",
  no_merch: "Kein Händler gefunden",
  in_link: "Interner Link",
  duration: "Dauer",
  up_img: "Foto hochladen",
  up_text: "Füge klare Fotos deines Produkts hinzu",
  ex_link: "Externer Link",
  save_sucess: "Erfolgreich gespeichert",

  // Händler bewerben
  app_as_merch: "Als Händler bewerben",
  reg_merch: "Als Händler registrieren",
  merch_email: "Gib die E-Mail des Händlers ein",
  merch_name: "Händlername",
  merch_text: "Muss mit der Unternehmensregistrierung identisch sein. Kann nicht geändert werden.",
  keyword: "Schlüsselwort",
  i_accpt:
    "Ich akzeptiere die allgemeinen ",
  i_accpt1:
    "Geschäftsbedingungen",
  i_accpt2:
    "des Unternehmens",
  i_accpt3:
    "Datenschutzbestimmungen",
  i_accpt4:
    "und den",
  i_accpt5:
    "Verhaltenskodex",
  continue: "Weiter",
  verify: "Verifizieren",
  pers_info: "Persönliche Informationen",
  fname: "Vorname",
  fname_holder: "Vorname eingeben",
  lname: "Nachname",
  lname_holder: "Nachname eingeben",
  st: "Straße",
  st_holder: "Straße eingeben",
  post_code: "Postleitzahl",
  dob: "Geburtsdatum",
  id_no: "ID Nummer",
  id_no_holder: "ID Nummer eingeben",
  valid: "Gültigkeit",
  tax_no: "Steuernummer",
  tax_holder: "Steuernummer eingeben",
  comp_type: "Unternehmensart",
  small: "Klein",
  large: "Groß",
  bus_info: "Geschäftsinformationen",
  comp_name: "Firmenname",
  comp_name_holder: "Firmenname eingeben",
  perm_establish: "Dauerhafte Einrichtung",
  imprint: "Impressum öffentliche Räumlichkeiten",
  timezone: "Zeitzone",
  weekly_hours: "Wöchentliche Arbeitszeiten festlegen",
  add_date: "Datum hinzufügen",
  add_date_text: "Füge Daten hinzu, an denen sich deine Verfügbarkeit von den wöchentlichen Arbeitszeiten ändert",
  select_date: "Datum auswählen",

  // KYB Bildschirm
  req: "Angefordert",
  app: "Genehmigt",
  rej: "Abgelehnt",
  // msg: "Nachricht",
  date: "Datum",

  // Überprüfung
  upload: "Uploads",
  view: "Ansehen",
  reject: "Ablehnen",
  approve: "Genehmigen",
  no_found: 'No Deal Found',

  // Alle Verkäufe
  overview: "ÜBERSICHT",
  coup_sold: "Verkaufte Gutscheine insgesamt",
  revenue: "Generierte Einnahmen",
  active_coup: "Aktive Gutscheine",
  exp_coup: "Abgelaufene Gutscheine",
  active_cred: "Gesamtaktive Credits",
  active_coup_cred: "Aktive Gutschein-Credits",
  popular_coup: "BELIEBTESTER GUTSCHEIN",

  // Blockiert / Freigegeben
  user: "Benutzer",
  unblc_user: "Benutzer freigeben",
  stop: "Stoppen",

  // Nachrichten
  inbox: "Posteingang",
  write: "Schreiben",
  // view: "Ansehen",
  all: "Alle",
  custom: "Kunde",
  merchant: "Händler",
  new_msg: "Neue Nachricht",
  to: "an",
  ///
  No_Coupon_Found: 'Kein Coupon gefunden',
  Not_Available: 'Nicht verfügbar',
  No_Merchant_Found: 'Kein Händler gefunden',

};