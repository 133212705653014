import { createSlice } from "@reduxjs/toolkit";
// import { getStore } from "../../api/store";
// import ApiRequest from '../../Services/ApiRequest';

const initialState = {

  store: {
    phone: "",
    code: "",
    country: "",
    lat: "",
    lng: "",
    address: "",
    logo: "",
    name: "",
    password: "",
    email: "",
    description: "",
    service_type: "",
    availability: "",
  },
  product: {
    // image: [],
    // name: "",
    // category: "",
    // description: "",
    // food: [],
  },
  storeSubscription: {},
  navIgation: [1],
  usersReceived: [],
  additionalValue: [],
  jewelleryItems: [],
  sizeTypes: [],
  editProduct: {},
  dashboard: [],
  editData: false,
  paymentPrice: ''
};

export const productSlice = createSlice({
  name: "teals_store",
  initialState: initialState,
  reducers: {
    additionalValue(state, action) {
      state.additionalValue = [...state.additionalValue, action.payload];
    },
    dashboard(state, action) {
      state.dashboard = action.payload;
    },
    setNavigation(state, action) {
      state.navIgation.push(action.payload)

    },
    jewelleryItems(state, action) {
      state.jewelleryItems = [...state.jewelleryItems, action.payload];
    },
    editAdditional(state, action) {
      state.additionalValue = action.payload;
    },
    editJewellery(state, action) {
      state.jewelleryItems = action.payload;
    },
    editSizes(state, action) {
      state.sizeTypes = action.payload;
    },
    editAdditionalModal(state, action) {
      const { index, newValue } = action.payload;
      const newAdditionalValue = [...state.additionalValue];
      newAdditionalValue[index] = newValue;
      state.additionalValue = newAdditionalValue;
    },
    editSizesModal(state, action) {
      const { index, newValue } = action.payload;
      const newSizes = [...state.sizeTypes];
      newSizes[index] = newValue;
      state.sizeTypes = newSizes;
    },
    editJewelleryModal(state, action) {
      const { index, newValue } = action.payload;
      const newJewelleryValue = [...state.jewelleryItems];
      newJewelleryValue[index] = newValue;
      state.jewelleryItems = newJewelleryValue;
    },
    sizeTypes(state, action) {
      state.sizeTypes = [...state.sizeTypes, action.payload];
    },
    usersReceived(state, action) {
      const { key, value } = action.payload;
      state.store[key] = value;
    },
    pushMenuItem: (state, action) => {
      state.product.menu = [...state.product.menu, action.payload];
    },
    setProduct(state, action) {
      state.product = action.payload;
    },
    setEditProduct(state, action) {
      state.editProduct = action.payload;
    },
    setEditData(state, action) {
      state.editData = action.payload;
    },
    setStoreSubscription(state, action) {
      const { key, value } = action.payload;
      state.storeSubscription = {
        ...state.storeSubscription,
        [key]: value
      };
    }
    ,
    setEditDataFood(state, action) {
      const { index, value } = action.payload;
      // Ensure that editData is initialized as an object
      if (typeof state.editData !== "object" || state.editData === null) {
        state.editData = { food: [] };
      }
      // Ensure that editData.food is defined
      if (!Array.isArray(state.editData.food)) {
        state.editData.food = [];
      }
      // Update the value at the specified index
      state.editData.food[index] = value;
    },
    setPaymentPrice(state, action) {

      state.paymentPrice = action.payload
    },
    productReceived(state, action) {
      const { key, value } = action.payload;
      if (state.hasOwnProperty("product")) {
        if (
          typeof state.product === "object" &&
          !Array.isArray(state.product)
        ) {
          state.product[key] = value;
        } else {
          state.product = { [key]: value };
        }
      } else {
        state.product = { [key]: value };
      }
    },

    editProductReceived(state, action) {
      const { key, value } = action.payload;
      if (state.hasOwnProperty("editProduct")) {
        if (
          typeof state.editProduct === "object" &&
          !Array.isArray(state.editProduct)
        ) {
          state.editProduct[key] = value;
        } else {
          state.editProduct = { [key]: value };
        }
      } else {
        state.editProduct = { [key]: value };
      }
    },
    userLogout(state) {
      state.store = initialState.store;
      state.product = initialState.product;
    },
    productEmpty(state) {
      state.product = initialState.product;
    },
    addtionalEmpty(state) {
      state.additionalValue = initialState.additionalValue;
    },
    sizeTypeEmpty(state) {
      state.sizeTypes = initialState.sizeTypes;
    },
    jewelleryEmpty(state) {
      state.jewelleryItems = initialState.jewelleryItems;
    },
    usersReceivedEmpty(state) {
      state.store = initialState.store;
    },
  },
});
export const fetchUserDataMiddleware = (store) => (next) => (action) => {
  return next(action);
};
export const {
  usersReceived,
  userLogout,
  addtionalEmpty,
  sizeTypeEmpty,
  pushMenuItem,
  productEmpty,
  usersReceivedEmpty,
  jewelleryItems,
  setEditProduct,
  productReceived,
  sizeTypes,
  jewelleryEmpty,
  editAdditional,
  setProduct,
  additionalValue,
  editProductReceived,
  editSizes,
  setEditData,
  editJewellery,
  setEditDataFood,
  setNavigation,
  setStoreSubscription,
  dashboard,
  setPaymentPrice,
  editJewelleryModal,
  editSizesModal,
  editAdditionalModal,
} = productSlice.actions;
