/* eslint-disable import/no-anonymous-default-export */
export default {
  //login
  login: "Login",
  login_text: "Welcome back! Log in and let’s get started!",
  email_user: "Enter your email orusername",

  //home page
  sup_admin: "Super Admin",
  admin: "Admin",
  mang: "Management",
  all_reg: "All Registers",
  top_merch: "Top Merchant",
  app_merch: "Apply Merchant",
  all_deal: "All Deals",
  all_coup: "All Coupons",
  all_mark: "All Markets",
  kyb: "KYB",
  all_sales: "All Sales",
  reports: "Reports",
  blc: "Blocked",
  rls: "Released",
  msg: "Message",
  security: 'Security',
  logout: "Logout",

  //Reports
  // reports: "Reports",
  new: "New",
  solved: "Solved",
  see_more: "See More",
  deal: "Deal",
  coup: "Coupon",
  comment: "Comment",
  merch: "Merchant",
  id: "ID",
  reporter: "Reporter",
  reDate: "Report Date",
  reason: "Reason",
  check: "Check",
  coupons: "Coupons",
  info: "Information",
  no_coup: "No Coupon Available",
  ph_no: "Phone Number",
  address: "Address",
  open_h: "Opening Hours",
  open_t: "Opening Time",
  report: "Report",
  send_res: "Send Reason",
  del: "Delete",

  // Deal Report
  dDetails: "Deal Details",
  desc: "Description",
  rec_deals: "Recommended Deals",
  go_deal: "Go to Deal",
  no_comment: "No Comment Found",
  no_found: 'No Deal Found',
  //coupon Report
  condition: "Conditions",
  sun: "Sunday",
  mon: "Monday",
  tue: "Tuesday",
  wed: "Wednesday",
  thr: "Thursday",
  fri: "Friday",
  sat: "Saturday",

  //Comment Reports
  ava_delivery: "Available Delivery",
  comments: "Comments",

  //Management screen
  cr_reporter: "Create Reporter",
  country: "Country",

  //create reporter screen

  cr_a_repo: "Create a Reporter",
  pers_id: "Personal ID",
  name: "Name",
  name_holder: "Enter Name",
  email: "Email Address",
  email_holder: "Enter Email Address",
  pass: "Password",
  pass_holder: "Enter Password",
  con_pass: "Confirm Password",
  con_holder: "Enter Password again",
  all_repo: "All Reporter",
  save: "Save",
  edit: "Edit",
  // del: "Delete",

  //All register screen
  user_cred: "User with Credits",
  user_Nocred: "User without Credits",
  merchs: "Merchants",
  search: "Search",
  sort: "Sort By",
  a_z: "A to Z",
  z_a: "Z to A",
  new_old: "Newest to Oldest",
  old_new: "Oldest to Newest",
  reg_date: "Registration Date",
  val_til: "Valid till",
  not_found: "Not found",
  details: "Details",
  blc_user: "Block User",
  block_popup: "User Blocked successfully",

  //top merchant
  reg: "Registered",
  other: "Others",
  find_merch: "Find Merchant",
  no_merch: "No Merchatn Found",
  in_link: "Internal Link",
  duration: "Duration",
  up_img: "Upload Photo",
  up_text: "Add clear photos of your product",
  ex_link: "External Link",
  save_sucess: "Saved Sucessfully",

  //Apply Merchant,
  app_as_merch: "Apply as Merchant",
  reg_merch: "Register as Merchant",
  merch_email: "Enter Merchant Email",
  merch_name: "Merchat Name",
  merch_text:
    "Must be Identical to the business registration. cannot be changed.",
  keyword: "Keyword",
  i_accpt:
    "I accept the general ",
  i_accpt1:
    "terms & conditions",
  i_accpt2:
    "of business",
  i_accpt3:
    "data protection Regulation",
  i_accpt4:
    "and the",
  i_accpt5:
    "code of conduction",
  continue: "Continue",
  verify: "Verify",
  pers_info: "Personal Information",
  fname: "First Name",
  fname_holder: "Enter First Name",
  lname: "Last Name",
  lname_holder: "Enter Last Name",
  st: "Street",
  st_holder: "Enter Street",
  post_code: "Postal Code",
  dob: "Date of Birth",
  id_no: "ID Number",
  id_no_holder: "Enter ID Number",
  valid: "Validity",
  tax_no: "Tax Number",
  tax_holder: "Enter Tax Number",
  comp_type: "Company Type",
  small: "Small",
  large: "Large",
  bus_info: "Business Information",
  comp_name: "Company Name",
  comp_name_holder: "Enter Company Name",
  perm_establish: "Permanent Establishment",
  imprint: "Imprint public premises",
  timezone: "TimeZone",
  weekly_hours: "Set your weekly hours",
  add_date: "Add date overrides",
  add_date_text:
    "Add dates when your availability changes from your weekly hours",
  select_date: "Select Date",

  //Kyb Screen
  req: "Requested",
  app: "Approved",
  rej: "Rejected",
  // msg: "Message",
  date: "Date",

  //check
  upload: "Uploads",
  view: "View",
  reject: "Reject",
  approve: "Approve",

  //All Sales
  overview: "OVERVIEW",
  coup_sold: "Total Coupons Sold",
  revenue: "Revenue Generated",
  active_coup: "Active Coupons",
  exp_coup: "Expired Coupons",
  active_cred: "Total active credits",
  active_coup_cred: "Active Coupons Credit",
  popular_coup: "MOST POPULAR COUPON",

  //blocked / released
  user: "User",
  unblc_user: "Un Block User",
  stop: "Stop",

  //Messages
  inbox: "Inbox",
  write: "Write",
  // view: "View",
  all: "All",
  custom: "Customer",
  merchant: "Merchant",
  new_msg: "New Message",
  to: "To",

  ///
  No_Coupon_Found: 'No Coupon Found',
  Not_Available: 'Not Available',
  No_Merchant_Found: 'No Merchant Found',
};
